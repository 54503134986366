<script>
import Multiselect from "vue-multiselect";

export default {
    name: 'AtendimentosRealizados',
    components: {Multiselect},
    props: {
        listProfessionals: { type: Array },
        listPacients: { type: Array },
        currentParams: { type: Object }
    },
    data() {
        return {
        }
    },
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="card-title">Preencha os campos abaixo para filtrar:</div>
      <form class="form-filter-report mt-3">
        <div class="row">
          <div class="col-md-6">
            <label for="date-ini">De:</label>
            <input type="date" name="date-ini" id="date-ini" class="form-control" placeholder="Data inicial" v-model="currentParams.date_ini">
          </div>
          <div class="col-md-6">
            <label for="date-end">Até:</label>
            <input type="date" name="date-end" id="date-end" class="form-control" placeholder="Data final" v-model="currentParams.date_end">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="professional">Profissional:</label>
            <Multiselect 
             id="professional"
             :options="listProfessionals"
             :multiple="true"
             v-model="currentParams.professionals"
             label="name"
             track-by="id"
             deselect-label="ENTER para remover"
             select-label="Enter para selecionar"
             placeholder="Selecione"
            >
            </Multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="pacient" class="text-left">Paciente:</label>
            <Multiselect 
             id="pacient"
             :options="listPacients"
             :multiple="true"
             v-model="currentParams.pacients"
             label="nome"
             track-by="id"
             deselect-label="ENTER para remover"
             select-label="Enter para selecionar"
             placeholder="Selecione"
            >
            </Multiselect>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>