<script>
import Vue from "vue";
import appConfig from "@/app.config";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";
import { http } from "@/helpers/easyindustriaapi/config";
import Swal from "sweetalert2";
import moment from "moment";
import atendimentoController from "@/controller/AtendimentoController";
import AtendimentosRealizados from "@/views/pages/relatorios/atendimentos/AtendimentosRealizados";

export default {
  page: {
    title: "Atendimentos",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { List, Insert, Edit, PageHeader, Layout },
  data() {
    return {
      listAgreement:[],
      listApproach:[],
      listResponsibles:[],
      listTrataments:[],
      listPacients:[],
      listProfessionals:[],
      listCodeTax:[],
      currentAtendimento:{},
      currentProfessional: {},
      professionals: [],
      inserindo: false,
      editando: false,
      titleBody: "Lista de Atendimentos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Atendimentos",
          href: "/atendimentos",
          active: true,
        },
      ],
      atendimentos:[],
      params: {
        professionals: null,
        pacients: null,
        date_ini: null,
        date_fim: null
      },
    };
  },
  computed: {
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.atendimentoController = new atendimentoController(this.currentUser, this.currentEmpresa);
  },
  mounted() {
    this.getData().then(() => {
      this.getTable()
    })
  },
  methods: {
    async doPost(atendimento) {
      Swal.fire({
        title: "Aguarde...",
        text: "Gravando dados...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      })
      atendimento.empresa_id = this.currentEmpresa.id;
      let response = await http
        .post("/atendimento?empresa_id=" + this.currentEmpresa.id, atendimento)
        .catch((error) => {
          Swal.close();
          this.makeToast("danger", error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        Swal.close();
        this.getData();
        this.back();
        this.makeToast("success", "Registro incluído");
      }
    },
    async doPut(atendimento) {
      Swal.fire({
        title: "Aguarde...",
        text: "Gravando dados...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        },
      })
      atendimento.user_id    = this.currentUser.id
      atendimento.start      = moment(atendimento.start).format("yyyy-MM-DD")
      atendimento.start_time = moment(atendimento.start_time, "HH:mm:ss").format("HH:mm")
      atendimento.end        = moment(atendimento.end).format("yyyy-MM-DD")
      atendimento.end_time   = moment(atendimento.end_time, "HH:mm:ss").format("HH:mm")
      let response = await http
        .put(
          "/atendimento/" + atendimento.id + "?empresa_id=" + this.currentEmpresa.id,
          atendimento
        )
        .catch((error) => {
          this.showWarning(error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.showSuccess("Registro alterado");
      }
    },
    async doUpdateSchedules(schedule) {
      console.log(schedule)
      Swal.fire({
        title: "Aguarde...",
        text: "Gravando dados...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading() // Exibe o loader enquanto a requisição está em andamento
        },
      })
      schedule.user_id      = this.currentUser.id
      schedule.oldDateEnd   = moment(schedule.oldDateEnd).format("yyyy-MM-DD")
      schedule.newDateStart = moment(schedule.newDateStart).format("yyyy-MM-DD")
      schedule.newTimeStart = moment(schedule.newTimeStart, "HH:mm:ss").format("HH:mm")
      schedule.newTimeEnd   = moment(schedule.newTimeEnd, "HH:mm:ss").format("HH:mm")
      let response = await http
        .put(
          "/atendimento/" + schedule.atendimentoId + "/updateSchedules?empresa_id=" + this.currentEmpresa.id,
          schedule
        )
        .catch((error) => {
          Swal.close
          this.showWarning(error.response.data)
        })

      if (response.status === 200) {
        // Toogle modal-update-schedules
        this.$bvModal.hide('modal-update-schedules')
        Swal.close()
        this.showSuccess("Atendimento reagendado com sucesso!")
      }
    },
    async doDelete(plano) {
      this.onLoader();
      plano.empresa_id = this.currentEmpresa.id;
      let response = await http.delete(
          "/atendimento/" + plano.id + "?empresa_id=" + this.currentEmpresa.id,
          plano
        )
        .catch((error) => {
          this.showWarning(error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("warning", "Registro excluído");
      }
    },
    async getTable() {
      Swal.fire({
        title: "Aguarde",
        text: "Carregando as tabelas...",
        allowOutsideClick: false,
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const response = await http.get("/atendimento/tabelas?empresa_id=" + this.currentEmpresa.id);

        if (response.status === 200) {
          this.listProfessionals = response.data.professionals;
          this.listPacients      = response.data.pacients;
          this.listTrataments    = response.data.specialtys;
          this.listApproach      = response.data.approachs;
          this.listAgreement     = response.data.agreements;
          this.listCodeTax       = response.data.codetax;
          Swal.close();
          return true;
        }
      } catch (error) {
        console.log(error.response);

        if (error.response.status != 403) {
          Swal.close();
          Swal.fire({
            icon: "warning",
            title: "Acesso negado",
            text: `Erro: ${error.response.data.message}\n.`,
          });
        }
      } finally {
        Swal.close();
      }
    },
    async getData() {
      Swal.close()
      Swal.fire({
        title: "Aguarde",
        text: "Carregando Atendimentos...",
        allowOutsideClick: false,
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      try {
        const response = await http.get("/atendimento?empresa_id=" + this.currentEmpresa.id)
          if (response.status === 200) {
            this.atendimentos = response.data
            Swal.close()
            return this.atendimentos
          }
      } catch (error) {
        console.log(error)
        this.showWarning(error.response.data)
      } finally {
        Swal.close()
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async insert() {
      this.inserindo = !this.inserindo;
    },
    edit(value) {
      this.currentAtendimento = value;
      this.editando = true;
    },
    // Method show SweetAlert Warning timer 5 seconds
    // Param: message = String
    showWarning(message) {
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: message,
        timer: 5000,
        showConfirmButton: false,
      });
      return true;      
    },
    // Method show SweetAlert Success timer 5 seconds
    // Param: message = String
    showSuccess(message) {
      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: message,
        timer: 2000,
        showConfirmButton: false,
      });
      return true;
    },
    // Modal SweetAlert definir parâmetros atendimentoController.evolutionCodeTaxPdf
    // SweetAlert with request to generate report
    showEvolutionCodeTaxPdf() {
      try {
        const result = Swal.fire({
          title: "Relatório Faturamento x Fiscal",
          text: "Relatório de atendimentos realizados com código de Fiscal",
          allowOutsideClick: false,
          showLoaderOnConfirm: true,
          confirmButtonText: "Visualizar",
          showCancelButton: true,
          cancelButtonText: "Fechar",
          html: `
            <div class="row">
              <div class="col-md-6">
                <label for="date_ini">Data Inicial</label>
                <input type="date" id="date_ini" class="form-control" required>
              </div>
              <div class="col-md-6">
                <label for="date_end">Data Final</label>
                <input type="date" id="date_end" class="form-control" required>
              </div>
            </div>`,
          focusConfirm: false,
          preConfirm: () => {
            return new Promise((resolve) => {
              this.params.date_ini = document.getElementById("date_ini").value;
              this.params.date_end = document.getElementById("date_end").value;
              this.atendimentoController.evolutionCodeTaxPdf(this.params).then(() => {
                resolve();
              });
            });
          },
        });

        if (result.isConfirmed) {
          this.makeToast("success", "Relatório gerado com sucesso!");
        }
      } catch (error) {
        this.makeToast("danger", error.response.data.message);
      }
    },
    // Modal SweetAlert definir parâmetros atendimentoController.gerarRelatorioAtendimentos
    // SweetAlert with request to generate report
    showAtedimentReport() {
      try
      {
        // Cria um div para montar o componente
        const wrapper = document.createElement("div");
        // Mostra o componente AtendimentosRealizados
        const componentAtendimentosRealizados = new (Vue.extend(AtendimentosRealizados))({
          propsData: {
            listProfessionals: this.listProfessionals,
            listPacients: this.listPacients,
            currentParams: this.params
          }
        }).$mount();
        // Adiciona o componente ao div
        wrapper.appendChild(componentAtendimentosRealizados.$el);

        const result = Swal.fire({
          title: "Relatório - Atendimentos realizados",
          allowOutsideClick: false,
          showLoaderOnConfirm: true,
          confirmButtonText: "Visualizar",
          showCancelButton: true,
          cancelButtonText: "Fechar",
          html: wrapper,
          preConfirm: () => {
            return new Promise((resolve) => {
              this.atendimentoController.gerarRelatorioAtendimentos(this.params).then(() => {
                resolve();
              });
            });
          },
        });

        if (result.isConfirmed) {
          this.makeToast("success", "Relatório gerado com sucesso!");
        }
      } catch (error) {
        this.makeToast("danger", error.response.data.message);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  <i class="far fa-calendar-plus"></i>
                  Incluir Atendimento
                </button>
                <button
                  @click="showEvolutionCodeTaxPdf(params)"
                  v-if="isHide"
                  type="button"
                  class="btn btn-light ml-3"
                >
                  <i class="fas fa-file-pdf"></i>
                  Rel. Fatur. x Fiscal
                </button>
                <button
                  @click="showAtedimentReport(params)"
                  v-if="isHide"
                  type="button"
                  class="btn btn-light ml-3"
                >
                  <i class="fas fa-file-pdf"></i>
                  Relat. Atendimentos
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="!isHide">
            <Insert
              v-if="inserindo"
              :currentEmpresa="currentEmpresa"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
              @back="back"
              :vet_profissionais="listProfessionals"
              :vet_pacientes="listPacients"
              :vet_tramentos="listTrataments"
              :vet_responsaveis="listResponsibles"
              :vet_abordagens="listApproach"
              :vet_convenios="listAgreement"
              :vet_codestax="listCodeTax"
            />
            <Edit
              v-if="editando"
              :currentEmpresa="currentEmpresa"
              :currentAtendimento="currentAtendimento"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
              @back="back"
              :listProfessionals="listProfessionals"
              :listPacients="listPacients"
              :listTrataments="listTrataments"
              :listApproach="listApproach"
              :listAgreement="listAgreement"
              :listCodestax="listCodeTax"
            />
          </div>
          <List
            v-if="isHide"
            :currentEmpresa="currentEmpresa"
            :listResponsibles="atendimentos"
            :hide="isHide"
            @newTitle="changeHomeTitle"
            @edit="edit"
            @doDelete="doDelete"
            @updateSchedules="doUpdateSchedules"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>