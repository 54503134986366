<script>
import { notificationMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    currentEmpresa: {type: Object, required: true},
    currentAtendimento: { type: Object },
    listProfessionals: { type: Array },
    listTrataments: { type: Array },
    listApproach: { type: Array },
    listAgreement: { type: Array },
    listPacients: { type: Array },
    listCodestax: { type: Array, required: true },
  },
  components: {},
  data() {
    return {
      convenios: [],
      responsaveis: [],
      abordagens: [],
      tratamentos: [],
      pacientes: [],
      profissionais: [],
      codestax:[],
      loader: {
        get: false,
      },
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Atendimentos",
          href: "/atendimentos",
          active: true,
        },
      ],
      titleBody: "Alterando",
      inserindo: true,
      submitted: false,
      service: {
        pacient: "",
        professional: "",
        agreement_id: -1, // convêcnio (Unimed, SulAmerica)
        service_value: "",
        day: [],
        hour: [],
        responsable_id: -1,
        contact: "",
        treatment: "",
        approach: "",
        local: "",
      },
      listRecurrence: [
        { id: 1, type: "none", name: "Nunca" },
        // { id: 2, type: "DAILY", name: "Diário" },
        { id: 3, type: "WEEKLY", name: "Semanal" },
        // { id: 4, type: "MONTHLY", name: "Mensal" },
        // { id: 4, type: "YEARLY", name: "Anual" },
      ],
    };
  },
  created() {
    this.profissionais = this.listProfessionals;
    this.pacientes     = this.listPacients;
    this.tratamentos   = this.listTrataments;
    this.responsaveis  = this.listResponsibles;
    this.abordagens    = this.listApproach;
    this.convenios     = this.listAgreement;
    this.codestax      = this.listCodestax;
    console.log(this.codestax);
    this.service       = this.currentAtendimento;
  },
  validations() {
    return {
      service: {
        pacient: { required },
        professional: { required },
        agreement_id: { required },
        service_value: { required },
        day: { required },
        hour: { required },
        responsable_id: { required },
        contact: { required },
        treatment: { required },
        approach: { required },
        local: { required },
      },
    };
  },
  mounted() {
    this.$emit("newTitle", "Alterando Atendimento");
    this.service.service_value = this.formatterFloatBR(this.service.service_value, 2);
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    getCidadeId(objects, key) {
      return objects.map(function (object) {
        return object[key];
      });
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    setPut() {
      this.service.service_value = this.new_convert_srting_float(
        this.service.service_value
      );
      this.$emit("doPut", this.service);
    },
    seleciona_paciente(event) {
      console.log(event);
    },
    seleciona_prof(event) {
      console.log(event);
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      this.setPost();
    },
    setPost() {
      this.service.service_value = this.new_convert_srting_float(
        this.service.service_value
      );
      this.$emit("doPost", this.service);
    },
    back() {
      this.$emit("back");
    },
    changeLoader(value) {
      this.loader.get = value;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="formSubmit" name="formAtendimento">
        <div class="form-row col-md-12">
          <div class="form-group col-md2">
            <b-input type="text" placeholder="Código" :value="service.id" disabled class="text-right"></b-input>
          </div>
        </div>
        <div class="form-row col-md-12">
          <div class="form-group col-md-4">
            <label for="nome">Paciente:</label>
            <select
              @change="seleciona_paciente($event)"
              class="custom-select mr-sm-2"
              id="selectProfessional"
              v-model="service.pacient_id"
              disabled
            >
              <option value="-1" selected="selected">
                Selecione o Paciente
              </option>
              <option
                v-for="(item, index) in pacientes"
                :key="index"
                :value="item.id"
              >
                {{ item.nome }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="selectAtendimentoProfessional">Profissional</label>
            <select
              @change="seleciona_prof($event)"
              class="custom-select mr-sm-2"
              id="selectProfessional"
              v-model="service.professional_id"
              disabled
            >
              <option value="-1" selected="selected">
                Selecione o profissional
              </option>
              <option
                v-for="(item, index) in profissionais"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-2">
            <label for="service_value">Valor do atendimento:</label>
            <input
              id="service_value"
              type="text"
              class="form-control text-right"
              placeholder="Ex: 70"
              v-model="service.service_value"
              @input="formatInputFloat($event, service, 'service_value')"
            />
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <h4 class="card-title col-md-12">Período recorrência</h4>
          <p class="col-md-12">Defina o período de recorrência do Atendimento</p>
          <div class="form-group col-md-2">
            <label for="end_hour">Tipo de recorrência</label>
            <select
              class="custom-select mr-sm-2"
              id="selectRecurrence"
              v-model="service.recurrence"
              disabled
            >
              <option v-for="recurrence in listRecurrence" :key="recurrence.id" :value="recurrence.type">{{ recurrence.name }}</option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label for="dayHour">Data início:</label>
            <input
              name="start_date"
              type="date"
              class="form-control"
              required
              v-model="service.start_date"
              disabled
            />
          </div>
          <div class="form-group col-md-2">
            <label for="start_time">Hora início:</label>
            <input
              name="start_time"
              type="time"
              class="form-control"
              required
              v-model="service.start_time"
              disabled
            />
          </div>
          <div class="form-group col-md-2">
            <label for="end_time">Hora fim:</label>
            <input
              name="end_time"
              type="time"
              class="form-control"
              v-model="service.end_time"
              disabled
            />
          </div>
          <div class="form-group col-md-3">
            <label for="end_date">Data fim:</label>
            <input
              name="end_date"
              type="date"
              class="form-control"
              v-model="service.end_date"
              disabled
            />
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-6">
            <label for="selectAtendimentoTreatment">Tratamento</label>
            <select
              class="custom-select mr-sm-2"
              id="selectTreatment"
              v-model="service.treatment_id"
            >
              <!--  -->
              <option value="-1" selected="selected">
                Selecione o tratamento
              </option>
              <option
                v-for="(item, index) in tratamentos"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-6">
            <label for="selectAtendimentoApproach">Abordagem</label>
            <select
              class="custom-select mr-sm-2"
              id="selectApproach"
              v-model="service.approach_id"
            >
              <option value="-1" selected="selected">
                Selecione a abordagem
              </option>
              <option
                v-for="(item, index) in abordagens"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="agreement_id">Convênio:</label>
            <select
              v-model="service.agreement_id"
              class="form-control"
              id="agreement_id"
            >
              <option
                v-for="agreement in convenios"
                :key="agreement.id"
                :value="agreement.id"
              >
                {{ agreement.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="agreement_id">Código Fiscal do Serviço:</label>
            <select
              v-model="service.code_tax_id"
              class="form-control"
              id="agreement_id"
            >
              <option value="" selected>
                Selecione o código
              </option>
              <option
                v-for="codetax in codestax"
                :key="codetax.id"
                :value="codetax.id"
              >
              {{ codetax.id }} - {{ codetax.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="localAtendimento">Local do atendimento:</label>
            <input
              id="localatendimento"
              type="text"
              class="form-control"
              placeholder="Local de atendimento"
              v-model="service.local"
            />
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <div class="col-md-12 mt-4">
            <button
              type="button"
              class="btn btn-warning"
              style="margin-right: 20px"
              @click="back()"
            >
              <i class="ri-close-line align-middle mr-2"></i> Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-success"
              style="margin-right: 20px"
              @click.prevent="setPut()"
            >
              <i class="ri-check-line align-middle mr-2"></i> Gravar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>