<template>
    <div>
        <b-modal id="modal-update-schedules" :title="`Atualizar Agendamentos do Atedimento ${schedule.atendimentoId}`" hide-footer size="lg"
            @hidden="onCancel"
        >
        <form-wizard 
         @on-complete="updateSchedules"
         color="#1cbb8c"
         back-button-text="Voltar"
         next-button-text="Avançar"
         finish-button-text="Gravar"
         :start-index="0"
         shape="tab"
         start-index.sync="stepIndex"
        >
            <tab-content icon="bx ri-calendar-event-line" title="Encerrar agendamento atual">
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="form-group row mb-3">
                            <label class="col-md-4 col-form-label" for="oldDateEnd">Encerrar atendimento atual em: </label>
                            <div class="col-md-4">
                                <b-form-input id="oldDateEnd" v-model="schedule.oldDateEnd" type="date"></b-form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </tab-content>
            <tab-content icon="bx ri-calendar-check-fill" title="Novo agendamento">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row mb-3">
                            <label class="col-md-4 col-form-label" for="newDateStart">Nova data: </label>
                            <div class="col-md-4">
                                <b-form-input id="newDateStart" v-model="schedule.newDateStart" type="date"></b-form-input>
                            </div>
                        </div>
                        <div class="form-group row mb-3">
                            <label class="col-md-4 col-form-label" for="newTimeStart">Novo horário inicial: </label>
                            <div class="col-md-4">
                                <b-form-input id="newTimeStart" v-model="schedule.newTimeStart" type="time" ></b-form-input>
                            </div>
                        </div>
                        <div class="form-group row mb-3">
                            <label class="col-md-4 col-form-label" for="newTimeEnd">Novo horário final: </label>
                            <div class="col-md-4">
                                <b-form-input id="newTimeEnd" v-model="schedule.newTimeEnd" type="time" ></b-form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </tab-content>
        </form-wizard>
        </b-modal>
    </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard"

export default {
    props: {
        atendimento: { type: Object },
    },
    components: {
        FormWizard,
        TabContent
    },
    data() {
        return {
            schedule: {
                atendimentoId: null,
                oldDateEnd: null,
                newDateStart: null,
                newTimeStart: null,
                newTimeEnd: null,
            },
        }
    },
    created() {
        // set the current date
        this.schedule.oldDateEnd = new Date().toISOString().slice(0, 10)
    },
    mounted() {
        this.schedule.atendimentoId = this.atendimento.id
    },
    methods: {
        updateSchedules() {
            console.log(this.schedule)
            this.$emit('doUpdateSchedules', this.schedule)
        },
        onCancel() {
            this.$emit('onCancel')
        }
    }
}
</script>